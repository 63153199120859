<template>
    <div ref="window" class="help">
      <Editor  v-if="$route.params.id" :key="$route.params.id"/>
      <div v-else class="help__text">
        <div class="help__title">Добро пожаловать</div>
        <p class="help__description">
          Активные скидки — это программа, которая помогает партнёрам Amway поддерживать стабильный товарооборот.
          <br>
          <br>
          Наберите больше 100 баллов* в текущем месяце и получите скидку в следующем!
          <br>
          <br>
          * Учитываются личные баллы и баллы клиентов.
        </p>
      </div>
    </div>
</template>

<script>
import Editor from '../../components/help/Editor.vue'

export default {
  components: { Editor },
    name: "Help"
}
</script>



<style scoped lang="scss">
  .help {
    &__title {
      margin-bottom: 15px;
      font-family: "Roboto Condensed", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 38px;
      color: #56565A;
    }

    &__description {
      font-family: "Source Sans Pro", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #56565A;
    }
  }
</style>
